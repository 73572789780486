// Header.js
import styles from "./Header.module.css";
import Logo from "components/Logo";
import Menu from "components/Menu";
import MenuResponsive from "components/MenuResponsive";

export default function Header() {
    return (
        <header className={styles.header}>
            <nav className={styles.headernav}>
                <Logo />
                <Menu />
                <MenuResponsive />
            </nav>
        </header>
    );
}