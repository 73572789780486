import styles from "./SectionFour.module.css";
import Image from 'react-image-webp';

export default function sectionFour() {
    return (
        <section className={styles.sectionFour}>
            <div className={styles.sectionFourContainer}>
                <p>
                    Tenho habilidades em <span>PHP, Magento, MySQL, ReactJS, JavaScript e muito mais, com práticas ágeis como Scrum e XP,</span>
                </p>
                <p className={styles.paragrafoMin}>
                    para oferecer soluções inovadoras e de alta performance no desenvolvimento de sistemas.
                </p>
            </div>

            <div className={styles.imagemFooter}>
                <Image
                    src={require(`assets/images/footer-tec-four.png`)}
                    webp={require(`assets/images/footer-tec-four.webp`)}
                    alt="Imagem de tecnologicas"
                    className={styles.imagemFooterDefault}
                />

                <Image
                    src={require(`assets/images/tecnologias-mobile.png`)}
                    webp={require(`assets/images/tecnologias-mobile.webp`)}
                    alt="Imagem de tecnologicas"
                    className={styles.imagemFooterMobile}
                />
            </div>
        </section>
    )
}
