import styles from "./MenuResponsive.module.css";
import { useState } from "react";
import { FaHamburger } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { NavLink } from "react-router-dom";

export default function MenuResponsive() {
    const [active, setActive] = useState(false);

    const toggleMenu = () => {
        setActive(!active);
    };

    return (
        <div className={styles.menuResponsive}>
            <div
                className={active ? styles.iconActive : styles.icon}
                onClick={toggleMenu}
            >
                {active ? <IoClose size={40} /> : <FaHamburger size={40} />}
            </div>

            <div
                className={`${styles.menuResponsiveList} ${
                    active ? styles.menuResponsiveListOpen : ''
                }`}
            >
                <ul className={styles.menuResponsiveListItens} onClick={toggleMenu}>
                    <li><NavLink to="/">Home</NavLink></li>
                    <li><NavLink to="/sobre-mim">Sobre Mim</NavLink></li>
                    <li><NavLink to="/projetos">Projetos</NavLink></li>
                </ul>
            </div>
        </div>
    );
}
