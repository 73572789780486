import React, { useState } from "react";
import imageCompression from "browser-image-compression";
import { saveAs } from "file-saver";
import styles from "./ImageConverter.module.css";

const ImageConverter = () => {
    const [images, setImages] = useState([]);
    const [convertedImages, setConvertedImages] = useState([]);

    const handleFileUpload = (e) => {
        const files = Array.from(e.target.files); // Recebe o array de arquivos
        setImages(files);
    };

    const convertImagesToWebP = async () => {
        if (images.length === 0) {
            alert("Por favor, carregue pelo menos uma imagem para converter.");
            return;
        }

        const results = [];

        try {
            for (const image of images) {
                const options = {
                    maxSizeMB: 1, // Configuração de compressão em MB (máx. 1 MB por imagem)
                    maxWidthOrHeight: 1920, // Tamanho no qual a imagem será redimensionada, se necessário.
                    useWebWorker: true,
                    fileType: "image/webp", // Define saída como WEBP
                };

                const compressedImage = await imageCompression(image, options);
                results.push({
                    originalName: image.name,
                    webpFile: compressedImage,
                });
            }

            setConvertedImages(results);
            alert("Imagens convertidas com sucesso!");
        } catch (error) {
            console.error("Erro na conversão:", error);
            alert("Erro ao converter imagens.");
        }
    };

    const downloadWebP = (webpFile, originalName) => {
        const fileName = originalName.replace(/\.[^/.]+$/, ".webp"); // Troca extensão para .webp
        saveAs(webpFile, fileName); // Função do file-saver para baixar o arquivo
    };

    return (
        <div>
            <h2>Conversor de Imagens para WEBP</h2>
            <p>Carregue imagens PNG ou JPG e as converta para formato WEBP.</p>

            {/* Upload de arquivos */}
            <input type="file" accept="image/png, image/jpeg" multiple onChange={handleFileUpload}  className={styles.inputFile} />
            <br />

            {images.length > 0 && (
                <p>
                    {images.length} imagem(ns) carregada(s):{" "}
                    {images.map((image) => (
                        <span key={image.name}>{image.name} </span>
                    ))}
                </p>
            )}

            {/* Botão de conversão */}
            <button onClick={convertImagesToWebP} className={styles.buttonWepb}>
                Converter para WEBP
            </button>

            {/* Lista de imagens convertidas */}
            {convertedImages.length > 0 && (
                <div>
                    <h4>Imagens Convertidas:</h4>
                    <ul>
                        {convertedImages.map((img, index) => (
                            <li key={index}>
                                {img.originalName}{" "}
                                <button onClick={() => downloadWebP(img.webpFile, img.originalName)} className={styles.buttonWepb}>
                                    Baixar
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ImageConverter;
