import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import PageDefault from "components/PageDefault";
import Footer from "components/Footer";
import ButtonUp from "components/ButtonUp";
import Start from "pages/Start";
import AboutMe from "pages/AboutMe";
import Projects from "pages/Projects";
import ConverterPage from "pages/ConverterPage";

function AppRoutes() {
    return (
        <BrowserRouter>
            <Header />

            <Routes>
                <Route path="/" element={<PageDefault />}>
                    <Route index element={<Start />} />
                    <Route path="/sobre-mim" element={<AboutMe />} />
                    <Route path="/projetos" element={<Projects />} />
                    <Route path="/converter-imagens" element={<ConverterPage />} />
                </Route>
                
                <Route path="*" element={<div>Página não encontrada</div>} />
            </Routes>

            <ButtonUp />
            <Footer />
        </BrowserRouter>
    );
}

export default AppRoutes;
