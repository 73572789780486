import styles from "./SectionOne.module.css";
import Image from "react-image-webp";
import { Helmet } from "react-helmet";

export default function SectionOne() {
    return (
        <>
            {/* Pré-carregar imagens críticas */}
            <Helmet>
                <link
                    rel="preload"
                    as="image"
                    href={require("assets/images/developer.webp")}
                    type="image/webp"
                />
                <link
                    rel="preload"
                    as="image"
                    href={require("assets/images/developer.png")}
                    type="image/png"
                />
            </Helmet>

            <section className={styles.sectionOne}>
                {/* Imagem crítica */}
                <div className={styles.developer}>
                    <Image
                        src={require("assets/images/developer.png")}
                        webp={require("assets/images/developer.webp")}
                        alt="Imagem de um programador em desenho"
                        decoding="async" // Melhor desempenho ao renderizar
                    />
                </div>

                {/* Imagem secundária com Lazy Loading */}
                <div className={styles.arrowDeveloper}>
                    <div className={styles.ArrowDeveloperMobile}>
                        <Image
                            src={require("assets/images/arrow-for-dev.png")}
                            webp={require("assets/images/arrow-for-dev.webp")}
                            alt="Imagem de uma seta"
                            loading="lazy" // Apenas carrega ao rolar
                        />
                    </div>

                    <p>
                        Hello! Eu sou <span>Rogerio Silva</span>
                    </p>
                </div>

                <div className={styles.informations}>
                    <p>Um Desenvolvedor que</p>
                    <h2 className={styles.informationH2}>
                        acredita que a <span>primeira impressão</span> é importante.
                    </h2>
                    <p className={styles.informationFooter}>
                        Afinal, uma capa bem feita pode despertar interesse e curiosidade.
                    </p>
                </div>
            </section>
        </>
    );
}
