import styles from "./Card.module.css";
import Image from 'react-image-webp';

export default function Card({imageName, alt, title, subtitle}) {
    return (
        <div className={styles.card}>
            <Image
                src={require(`assets/images/${imageName}.png`)}
                webp={require(`assets/images/${imageName}.webp`)}
                alt={alt}
            />
            <div className={styles.containerCardRight}>
                <h3>{title}</h3>
                <p>{subtitle}</p>
                <button>Saiba mais...</button>
            </div>
        </div>
    )
}