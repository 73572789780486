import styles from "./SectionTwo.module.css";
import { Link } from "react-router-dom";
import { ReactComponent as LogoAscensus } from "assets/images/ascensus-globo.svg";

export default function sectionOne() {
    return (
        <section className={styles.sectionTwo}>
            <h2>Trabalho com desenvolvimento de software.</h2>

            <p>
                Atualmente, sou Engenheiro de Software na <Link to="https://ascensus.com.br/" target="_blank"><LogoAscensus height={25} className={styles.logoAscensus} /> <span className={styles.company}>Ascensus Group</span></Link>.
            </p>

            <p>
                Designer UI/UX autodidata, com mais de 10 anos de experiência no E-commerce.
                Crio produtos digitais significativos e agradáveis que equilibram as necessidades do usuário com os objetivos do negócio.
            </p>
        </section>
    )
}