// Logo.js
import styles from "./Logo.module.css";
import { NavLink } from "react-router-dom";
import Image from "react-image-webp";

export default function Logo() {
    return (
        <div className={styles.logotipo}>
            <NavLink to="/">
                <Image
                    src={require(`assets/images/logo.png`)}
                    webp={require(`assets/images/logo.webp`)}
                    alt="Logotipo do Portifólio"
                />
            </NavLink>
        </div>
    );
}
