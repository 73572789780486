// Menu.js
import { NavLink } from "react-router-dom";
import styles from "./Menu.module.css";

export default function Menu() {
    return (
        <div className={styles.menu}>
            <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/sobre-mim">Sobre Mim</NavLink></li>
                <li><NavLink to="/projetos">Projetos</NavLink></li>
            </ul>
        </div>
    );
}
