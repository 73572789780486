import styles from "./AboutMe.module.css";
import TitlePage from "components/TitlePage";
import AboutMeText from "components/AboutMe/AboutMeText";

export default function AboutMe() {
    return (
        <main className={styles.container}>
            <TitlePage title="Sobre Mim" />
            <AboutMeText />
        </main>
    )
}