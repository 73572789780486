import { FaLinkedin, FaInstagram, FaGithub, FaRegCopyright } from "react-icons/fa";
import styles from "./Footer.module.css";
import { Link } from "react-router-dom";

export default function SectionThree() {
    return (
        <footer className={styles.footer}>
            <div className={styles.footerContainer}>
                <h2>Contato</h2>

                <div className={styles.socialMedia}>
                    <Link 
                        to="https://www.instagram.com/rogerio.alvest/" target="_blank"><FaInstagram />
                    </Link> 
                    
                    <Link to="https://www.linkedin.com/in/rog%C3%A9rio-silva-16881129/" target="_blank">
                        <FaLinkedin />
                    </Link>

                    <Link to="https://github.com/rogerdgela" target="_blank">
                        <FaGithub />
                    </Link>
                </div>

                <p>
                    Todos os direitos reservados <FaRegCopyright />.
                    <br/>
                    Desenvolvimento by Rogério Silva
                </p>
            </div>
            
        </footer>
    )
}