import styles from "./Start.module.css";
import SectionOne from "components/Start/SectionOne";
import SectionTwo from "components/Start/SectionTwo";
import SectionThree from "components/Start/SectionThree";
import SectionFour from "components/Start/SectionFour";
import SectionFive from "components/Start/SectionFive";

export default function Start() {
    return (    
        <main className={styles.container}>
            <SectionOne /> 
            <SectionTwo />
            <SectionThree />
            <SectionFour />
            <SectionFive />
        </main>     
    )
}