import styles from "./SectionFive.module.css";
import Image from 'react-image-webp';

export default function SectionThree() {
    return (
        <section className={styles.sectionFive}>
            <div className={styles.projectLeft}>
                <div className={styles.sideLeft}>
                    <h3>Projeto em destaque</h3>
                    <h2>ProImprensa</h2>
                    <div className={styles.textProject}>
                        <p>O projeto da "ProImprensa" é de uma agência de comunicação que oferece marketing digital, assessoria de imprensa, vídeos e suporte a eventos. Desenvolvido com tecnologia responsiva, PHP, MySQL, SEO e JavaScript, o site destaca sua experiência com pessoas, marcas e governos. Inclui postagens de blog, informações sobre a equipe, opções de contato e links para redes sociais.</p>
                    </div>
                </div>

                <div className={styles.sideRight}>
                    <Image
                        src={require(`assets/images/project-proimprensa.jpg`)}
                        webp={require(`assets/images/project-proimprensa.webp`)}
                        alt="Imagem do projeto Proimprensa"
                    />
                </div>
            </div>

            <div className={styles.projectRight}>
                <div className={styles.sideRight}>
                    <Image
                        src={require(`assets/images/project-motorsbr.jpg`)}
                        webp={require(`assets/images/project-motorsbr.webp`)}
                        alt="Imagem do projeto MotorsBR"
                    />
                </div>

                <div className={styles.sideRight}>
                    <h3>Projeto em destaque</h3>
                    <h2>Motors BR</h2>
                    <div className={styles.textProject}>
                        <p>O Projeto "MOTORSBR" é um e-commerce de peças e acessórios automotivos, desenvolvido com Magento 2, aproveitando sua arquitetura modular e responsiva, integração com APIs, e robustas funcionalidades de segurança. Ele oferece ferramentas de marketing, gestão de produtos, e otimização de performance, além de ser altamente escalável. A plataforma suporta personalização e extensões, permitindo um site eficiente e seguro para consumidores.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}