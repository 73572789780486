import React, { useState, useEffect } from "react";
import { FaArrowUp } from "react-icons/fa"; 
import styles from "./ButtonUp.module.css"; 

export default function ButtonUp() {
    const [isVisible, setIsVisible] = useState(false); // Estado para controlar a visibilidade do botão

    // Função que executa o scroll suave ao clique
    const scrollToTop = () => {
        window.scrollTo({
            top: 0, // Vai para o topo da página
            behavior: "smooth", // Animação suave
        });
    };

    // Função que controla a visibilidade do botão com base na rolagem da página
    const handleScroll = () => {
        const scrollPosition = window.scrollY; // Deslocamento vertical atual
        if (scrollPosition > 300) {
            setIsVisible(true); // Exibe o botão se rolar mais de 300px
        } else {
            setIsVisible(false); // Esconde o botão caso esteja no topo
        }
    };

    // Adiciona e remove o evento de scroll
    useEffect(() => {
        window.addEventListener("scroll", handleScroll); // Adiciona o ouvinte para detectar scroll
        return () => window.removeEventListener("scroll", handleScroll); // Remove o ouvinte ao desmontar o componente
    }, []);

    return (
        <div>
            {/* Mostra o botão apenas se o estado "isVisible" estiver verdadeiro */}
            {isVisible && (
                <div
                    className={`${styles.button} ${styles.visible}`} // Estilização com module CSS
                    onClick={scrollToTop} // Aciona a rolagem ao clicar
                >
                    <FaArrowUp className={styles.icon} /> {/* Ícone de seta para cima */}
                </div>
            )}
        </div>
    );
}
