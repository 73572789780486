import styles from "./AboutMeText.module.css";

export default function AboutMeText() {
    return (
        <section className={styles.section}>
            <p className={styles.backgroundText}>Olá! Meu nome é Rogerio Silva e sou um profissional apaixonado por tecnologia da informação, com mais de 20 anos de experiência na área. Minha jornada começou com a formação técnica em Tecnologia da Informação pelo SENAC em 2003, seguida pela graduação em Ciência da Computação pela Faculdade Comunitária de Limeira em 2008.</p>

            <p className={styles.topic}>Formação Acadêmica</p>

            <div className={styles.backgroundText}>
                <p>
                    - Graduação em Ciência da Computação (2008)<br />
                    <strong>Instituição:</strong> Faculdade Comunitária de Limeira
                </p>

                <p>
                    - Técnico em Tecnologia da Informação (2003)<br />
                    <strong>Instituição:</strong> SENAC
                </p>
            </div>


            <p className={styles.topic}>Experiência Profissional</p>

            <div className={styles.backgroundText}>
                <p>Ao longo da minha carreira, tive a oportunidade de trabalhar em diversas instituições e empresas, onde pude aplicar e expandir meus conhecimentos em TI:</p>

                <p>Prefeitura de Limeira: Colaborei na automação de processos administrativos e na gestão de dados públicos.</p>

                <p>Receita Federal de Limeira: Participei de projetos voltados para sistemas fiscais e tributários, contribuindo para a eficiência dos processos de arrecadação e fiscalização.</p>

                <p>Brainsoft: Desenvolvi e implementei soluções de software, além de prestar suporte técnico em um ambiente dinâmico.</p>

                <p>Polihouse do Brasil: Atuei em projetos de TI específicos para o setor comercial.</p>

                <p>Ascensus Group (Atual): Atualmente, estou focado em projetos inovadores que envolvem a integração de sistemas e automação de processos empresariais.</p>
            </div>

            <p className={styles.topic}>Especialização em Comércio Eletrônico</p>
            
            <div  className={styles.backgroundText}>
                <p>Possuo vasta experiência em comércio eletrônico, com foco em integrações de marketplaces utilizando as plataformas Magento 1 e 2. Minha expertise inclui:</p>

                <p>Desenvolvimento e personalização de lojas virtuais.</p>
                <p>Integração de sistemas de e-commerce com marketplaces.</p>
                <p>Automação de processos de vendas e gestão de inventário.</p>
                <p>Otimização de desempenho de lojas online.</p>
            </div>

            <p className={styles.topic}>Cursos e Certificações</p>
            
            <div  className={styles.backgroundText}>
                <p>Ao longo da minha carreira, sempre busquei me atualizar e expandir meus conhecimentos através de diversos cursos e certificações, garantindo que estou sempre alinhado com as melhores práticas e tecnologias mais recentes do mercado.</p>

                <p>Se precisar de mais informações ou quiser discutir um projeto, não hesite em entrar em contato comigo. Estou sempre aberto a novas oportunidades e desafios!</p>

                <p>Espero que este texto atenda às suas expectativas para a página web. Se precisar de ajustes ou mais informações, é só me avisar!</p>
            </div>
        </section>
    )
}