import ImageConverter from "components/ImageConverter";
import React from "react";


const ConverterPage = () => {
  return (
    <div>
      <h1>Página do Conversor de Imagens</h1>
      <ImageConverter />
    </div>
  );
};

export default ConverterPage;
