import styles from "./SectionThree.module.css";
import Card from "components/Card";

export default function SectionThree() {
    return (
        <section className={styles.sectionThree}>
            <div className={styles.titleSectionThree}>
                <h2>Experiência Profissional</h2>
            </div>

            <div className={styles.cards}>
                <Card 
                    className={styles.card} 
                    imageName="img-card01"
                    alt="Logotigo Receita Federal"
                    title="Suporte de TI - Receita Federal, Limeira, SP"
                    subtitle="Prestei suporte técnico em TI para o setor administrativo, atuando na manutenção e configuração de equipamentos, redes e sistemas utilizados pela Receita Federal." 
                />

                <Card 
                    className={styles.card} 
                    imageName="img-card02"
                    alt="Logotigo Prefeitura de Limeira"
                    title="Desenvolvedor Web - Prefeitura de Limeira, SP"
                    subtitle="Responsável pelo desenvolvimento e manutenção de portais e sistemas internos da prefeitura, com foco em otimização e acessibilidade para o cidadão." 
                />

                <Card 
                    className={styles.card} 
                    imageName="img-card03"
                    alt="Logotigo BrainSoft"
                    title="Desenvolvedor de Software - BrainSoft"
                    subtitle="Atuei no desenvolvimento do sistema de PDV e ERP para clientes de diferentes setores, participando de todas as etapas do ciclo de vida do software, com a linguaguem Deplhi." 
                />
                
                <Card 
                    className={styles.card} 
                    imageName="img-card04"
                    alt="Logitipo Polihouse do Brasil"
                    title="Desenvolvedor Web - Polihouse do Brasil"
                    subtitle="Fui responsável pela criação e manutenção de sistemas de gestão e automação de processos para o setor industrial, desenvolvendo interfaces intuitivas e funcionais que facilitavam o  controle e monitoramento..." 
                />
            </div>
        </section>
    )
}